import React from 'react'
import phone from '../../images/Group 377.png';
import './Quote.css'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import Pulse from 'react-reveal/Pulse';

export default function Quote({ quote, themeColor, ...props }) {
  return (
    <section className="quote" style={{ background: `${themeColor}1a` }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div className="inner-quote d-flex flex-column">
              <Fade top duration={1000}>
                <h2 className="quote-title">{quote.title}</h2>
              </Fade>
              <Fade bottom duration={1000}>
                <span className="quote-description">{quote.description.description}</span>
              </Fade>
              <button className="btn quote-btn" style={{ background: themeColor }}>{quote.buttonText}</button>
            </div>
          </div>
          <Pulse right duration={3000}>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              {/*<img src={quote.heroImage.fluid.src} alt="phone-img" className="img-fluid" />*/}
            </div>
          </Pulse>
        </div>
      </div>
    </section>
  )
}
