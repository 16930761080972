import React, { useRef, useState, useEffect } from "react"
import "./Work.css"
import VanillaTilt from 'vanilla-tilt';
import Fade from 'react-reveal/Fade'

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

export default function Work({ work, ...props }) {

  const options = {
    scale: 1.2,
    speed: 1000,
    max: 20
  };

  return (
    <section className="demo">
      <div className="row mx-0">
        {work.map(p => {
          return (
            <div className="col-12 col-md-6 px-0">
              <div
                style={{ background: p.backgroundColor }}
                className="square-one d-flex justify-content-center align-items-center flex-column">
                <Fade>
                  <Tilt className="box" options={options} className="position-relative d-flex justify-content-center">
                    {/* <img*/}
                    {/*  src={p.serviceImage.fluid.src}*/}
                    {/*  className="work-img"*/}
                    {/*  alt="work-img"*/}
                    {/*/>*/}
                    <div className="card-work-title">
                      <h2 className="mb-0">{p.title}</h2>
                    </div>
                  </Tilt>
                  <div className="work-text-area">
                    <h2 className="text-right pr-3">{p.number}</h2>
                    <h3 className="text-right">{p.description.description}</h3>
                  </div>
                </Fade>
              </div>
            </div>
          )
        })}
      </div>
      {/* <Tilt className="box" options={options} /> */}

    </section>
  )
}
