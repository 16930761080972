import React, { useState } from 'react'
import './Feature.css'
import Fade from 'react-reveal/Fade'


export default function Feature({ feature, ...props }) {
  return (
    <section>
      <div className="container container-padding">
        <div className="row web-feature">
          {feature.map((p, i) => (
            !(i % 2) ?
              <>
                <div className="col-12 col-md-6 d-block d-md-none">
                  {/* <img src={p.subDescription.subDescription} className="mb-0" alt="img" /> */}
                </div>
                <div className="col-12 col-md-6">
                  <Fade top duration={1000}>
                    <h2>{p.title.split(',').reduce((all, cur) => [
                      ...all,
                      <br />,
                      cur
                    ])} </h2>
                  </Fade>
                  <div className="row">
                    {p.ServiceType.map((p, i) => {
                      return (
                        <>
                          <Fade bottom duration={i === 0 ? 1000 : i === 1 ? 2000 : i === 2 ? 3000 : i === 3 ? 3500 : ''}>
                            <div className="col-3 col-md-2 d-flex">
                              <div className="d-flex justify-content-center square-box align-items-center w-100">
                                {/*<img src={p.serviceImage.fluid.src} className="mb-0" alt="icon" />*/}
                              </div>
                            </div>
                          </Fade>
                          <Fade bottom duration={i === 0 ? 1000 : i === 1 ? 2000 : i === 2 ? 3000 : i === 3 ? 3500 : ''}>
                            <div className="col-9 col-md-10">
                              <p>{p.description.description}</p>
                            </div>
                          </Fade>
                        </>
                      )
                    })}
                  </div>
                </div>
                <Fade right duration={2000}>
                  <div className="col-12 col-md-6 d-none d-md-block">
                    {/* <img src={p.subDescription.subDescription} className="mb-0" alt="img" /> */}
                  </div>
                </Fade>
              </>
              :
              <>
                <Fade left duration={2000}>
                  <div className="col-12 col-md-6">
                    {/* <img src={p.subDescription.subDescription} className="mb-0" alt="img" /> */}
                  </div>
                </Fade>
                <div className="col-12 col-md-6">
                  <Fade top duration={1000}>
                    <h2>{p.title.split(',').reduce((all, cur) => [
                      ...all,
                      <br />,
                      cur
                    ])} </h2>
                  </Fade>
                  <div className="row">
                    {p.ServiceType.map(p => {
                      return (
                        <>
                          <Fade bottom duration={i === 0 ? 1000 : i === 1 ? 2000 : i === 2 ? 3000 : i === 3 ? 3500 : ''}>
                            <div className="col-3 col-md-2 d-flex">
                              <div className="d-flex justify-content-center square-box align-items-center w-100">
                                {/*<img src={p.serviceImage.fluid.src} className="mb-0" alt="icon" />*/}
                              </div>
                            </div>
                          </Fade>
                          <Fade bottom duration={i === 0 ? 1000 : i === 1 ? 2000 : i === 2 ? 3000 : i === 3 ? 3500 : ''}>
                            <div className="col-9 col-md-10">
                              <p>{p.description.description}</p>
                            </div>
                          </Fade>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
          ))}
        </div>
      </div>
    </section>
  )
}
