import React from 'react'
import './ServiceWeProvide.css'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

export default function ServiceWeProvide({ serviceWeProvide, themeColor, ...props }) {
  return (
    <section className="service-provide">
      <div className="dynamic-image img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-second img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="container">
        <Fade bottom duration={1000}>
          <h2 className="text-center service-we-heading mb-0">{serviceWeProvide.title}</h2>
          <div className="d-flex justify-content-center">
            <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
          </div>
        </Fade>
        <div className="row service-we">
          {serviceWeProvide.ServiceType.map((p) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 service-we-provide">
                <div className="card position-relative">
                  <div className="card-body">
                    <Fade bottom>
                      {/*<img src={p.serviceImage.fluid.src} className="img-fluid service-icon" alt="xd-icon" />*/}
                    </Fade>
                    <Fade top duration={1500}>
                      <h2 className="service-title">{p.title}</h2>
                    </Fade>
                    <Fade bottom duration={1000}>
                      <p className="service-description">{p.description.description}</p>
                    </Fade>
                  </div>
                </div>
              </div>
            )
          })}
          {/* <div className="col-12 col-md-6 col-lg-4 service-we-provide">
            <div className="card position-relative">
              <div className="card-body">
                <img src={ps} className="img-fluid service-icon" alt="xd-icon" />
                <h2 className="service-title">Adobe <br /> PhotoShop</h2>
                <p className="service-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 service-we-provide">
            <div className="card position-relative">
              <div className="card-body">
                <img src={ai} className="img-fluid service-icon" alt="xd-icon" />
                <h2 className="service-title">Adobe <br /> Illustrator</h2>
                <p className="service-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 service-we-provide">
            <div className="card position-relative">
              <div className="card-body">
                <img src={io} className="img-fluid service-icon" alt="xd-icon" />
                <h2 className="service-title">Prototype.io</h2>
                <p className="service-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 service-we-provide">
            <div className="card position-relative">
              <div className="card-body">
                <img src={zeplin} className="img-fluid service-icon" alt="xd-icon" />
                <h2 className="service-title">Zeplin</h2>
                <p className="service-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
