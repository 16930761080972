import React, { useState } from 'react'
import line from '../../../images/Group 675.png'
import './HowItGoing.css'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

export default function HowItGoing({ howItGoing, themeColor, ...props }) {
  return (
    <section className="how-it-going">
      <div className="container">
        <Fade bottom>
          <h2 className="text-center how-it-going-heading mb-0">How it's going</h2>
          <div className="d-flex justify-content-center">
            <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
          </div>
        </Fade>
        <div className="row position-relative how-it-container">
          <Fade top duration={1000}>
            <img src={line} className="img-fluid draw-line d-none d-md-flex" alt="line-img" />
          </Fade>
          {howItGoing.ServiceType.map((p, i) => (
            <>
              <div className={`${i === 0 ? "col-3" : "col-2"} how-it-going-contain-size d-none d-md-flex`} >
                <Fade top duration={1000}>
                  <h3 className="mb-0 how-it-going-num">{p.number}</h3>
                </Fade>
                <Fade top duration={1000}>
                  <div className="how-it-circle">
                    {/*<img src={p.serviceImage.fluid.src} className="mb-0" alt="icon" />*/}
                  </div>
                </Fade>
                <Fade bottom duration={500}>
                  <h2 className="mb-0 how-it-going-title">{p.title}</h2>
                </Fade>
              </div>
            </>
          ))}
          {howItGoing.ServiceType.map((p, i) => (
            <div className="col-12 col-sm-6 d-block d-md-none">
              <div className="row">
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <Zoom duration={1000} delay={i * 500}>
                    <div className="how-it-circle">
                      {/*<img src={p.serviceImage.fluid.src} className="img-fluid mb-0" alt="info" />*/}
                    </div>
                  </Zoom>
                </div>
                <div className="col-8">
                  <Fade right duration={1000} delay={i * 500}>
                    <h2 className="mb-0 how-it-going-num">{p.number}</h2>
                  </Fade>
                  <Fade right duration={1000} delay={i * 500}>
                    <h3 className="mb-0  how-it-going-title">{p.title}</h3>
                  </Fade>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
