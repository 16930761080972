import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Navbar from "../components/Dashboard/Navbar/Navbar"
import Header from "../components/Dashboard/Header/Header"
import "./style.css"
import Quote from "../components/Quote/Quote"
import Footer from "../components/Dashboard/Footer/Footer"
import Work from "../components/WebDesing/Work/Work"
import ServiceWeProvide from "../components/WebDesing/ServiceWeProvide/ServiceWeProvide"
import HowItGoing from "../components/WebDesing/HowItGoing/HowItGoing"
import Feature from "../components/WebDesing/Feature/Feature"
import LessIcon from "../images/expand_less_black_24dp.svg"


export const query = graphql`
    query WebQuery {
    webDesign: contentfulWebDesign(slug: {eq: "/web-design"}) {
    id
    themeColor
    navbars {
      title
      to
      hRef
    }
    header {
      title
      description {
        description
      }
      actionImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slides {
      title
      number
      backgroundColor
      description {
        description
      }
      serviceImage {
         gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     serviceWeProvide {
      title
      ServiceType {
        title
        description {
          description
        }
        serviceImage {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    howItsGoing {
      title
      ServiceType {
        title
        number
        serviceImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    quote {
      title
      description {
        description
      }
      heroImage {
         gatsbyImageData(layout: FULL_WIDTH)
      }
      buttonText
    }
     work {
      title
      
      ServiceType {
        description {
          description
        }
        serviceImage {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
     footer {
      logo {
         gatsbyImageData(layout: FULL_WIDTH)
      }
      email
      phoneNumber
      footerHeading {
        title
        footerSection {
          slug
          text
        }
      }
      socialLink {
        icon {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    footerWaveColor {
      firstWaveColor
      secondWaveColor
    }
    copyright {
      title
    }
  }
}
    `

const WebDesign = ({ location, data: { webDesign } }) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <>
      <SEO title="Home" />
      <Navbar navbar={webDesign.navbars} themeColor={webDesign.themeColor} />
      {/* <Header header={webDesign.header} /> */}
      <Header header={webDesign.header.reduce((p, c) => ({ ...p, ...c }), {})} />
      {/* <CustomHeader header={webDesign.header.reduce((p, c) => ({ ...p, ...c }), {})} /> */}
      <Work work={webDesign.slides} />
      <ServiceWeProvide serviceWeProvide={webDesign.serviceWeProvide} themeColor={webDesign.themeColor} />
      <HowItGoing howItGoing={webDesign.howItsGoing} themeColor={webDesign.themeColor} />
      <Quote quote={webDesign.quote} themeColor={webDesign.themeColor} />
      <Feature feature={webDesign.work} />
      <Footer footer={webDesign.footer} copyright={webDesign.copyright} />
      {/* <Footer footer={webDesign.footer} copyright={webDesign.copyright} footerWaveColor={webDesign.footerWaveColor} /> */}
      <div className="scroll-button-circle" onClick={scrollHandler}
           style={{ display: scrollPosition > 1000 ? "flex" : "none", background: webDesign.themeColor }}>
        <img src={LessIcon} alt="lessIcon" className="mb-0 less-icon" />
      </div>
    </>
  )
}

export default WebDesign
